import React, { useEffect, useRef } from 'react';
import useSound from 'use-sound';

import { useInView } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { setAudioPlaying, setHasAudio } from '../state/ui/uiSlice';
import { selectAudioEnabled } from '../state/ui/audioSelectors';

interface Props {
  soundUrl: string;
  playOnce?: boolean;
  playOnFirstInteraction?: boolean;
}

export default function SoundOnScroll({
  playOnce = false,
  soundUrl,
  playOnFirstInteraction = false,
}: Props) {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const dispatch = useDispatch();
  const [hasPlayed, setHasPlayed] = React.useState(false);
  const audioEnabled = useSelector(selectAudioEnabled);

  const [play, data] = useSound(soundUrl, {
    soundEnabled: audioEnabled,
  });

  useEffect(() => {
    dispatch(setHasAudio(true));

    return () => {
      dispatch(setHasAudio(false));
    };
  }, []);

  useEffect(() => {
    if (!audioEnabled) {
      data.stop();
      return;
    }
    if (isInView && !hasPlayed) {
      play();
      setHasPlayed(true);
    }
    if (playOnce) return;
    if (!isInView && hasPlayed) {
      setHasPlayed(false);
    }
  }, [isInView, playOnce, audioEnabled, data, play, hasPlayed]);

  useEffect(() => {
    if (data.sound === null) return;
    data.sound.onplay = () => {
      dispatch(setAudioPlaying(true));
    };
    data.sound.onstop = () => {
      dispatch(setAudioPlaying(false));
    };
    data.sound.onend = () => {
      dispatch(setAudioPlaying(false));
    };
    if (playOnFirstInteraction) {
      data.sound.once('unlock', function () {
        if (audioEnabled) {
          data.sound.play();
        }
      });
    }
  }, [
    data.sound,
    dispatch,
    setAudioPlaying,
    audioEnabled,
    playOnFirstInteraction,
  ]);

  return <div ref={ref}></div>;
}
