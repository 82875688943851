import React from 'react';
import SoundOnScroll from '../SoundOnScroll';

export default function DynamicSoundOnScroll({ blok }) {
  return (
    <SoundOnScroll
      playOnce={blok.playOnce}
      playOnFirstInteraction={blok.playOnFirstInteraction}
      soundUrl={blok.sound.filename}
    />
  );
}
